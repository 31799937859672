import * as ActiveStorage from "@rails/activestorage"
ActiveStorage.start()

import Trix from "trix" // import "@rails/actiontext"
window.Trix = Trix;

import TrixUploader from "./trix_uploader";

Trix.config.input = {
  level2Enabled: !0,
  getLevel: function(){
    return this.level2Enabled && Trix.browser.supportsInputEvents ? 2 : 0
  },

  pickFiles: function(callback){
    const input = window.Trix.makeElement("input", { type:"file", multiple: false, hidden: true, accept: "image/*", id: this.fileInputId });

    input.addEventListener("change", function(){
      callback(input.files);
      return Trix.removeNode(input)
    });

    Trix.removeNode(document.getElementById(this.fileInputId));
    document.body.appendChild(input);
    input.click()
  },

  fileInputId: `trix-file-input-${Date.now().toString(16)}`
};

Trix.config.attachments.preview.caption = {
  name: false,
  size: false
};

addEventListener("trix-initialize", function(event) {
  if (event.target.dataset.attachments !== "enabled") {
    const fileButton = event.target.toolbarElement.querySelector("[data-trix-button-group=file-tools]");

    if (fileButton)
      fileButton.remove();
  }
});

addEventListener("trix-file-accept", function(event) {
  const isAnImageFile = function(file) {
    return file && file["type"].split("/")[0] === "image";
  };

  if (event.target.dataset.attachments !== "enabled") {
    return event.preventDefault();
  }

  if (!isAnImageFile(event.file)) {
    return event.preventDefault();
  }
});

addEventListener("trix-attachment-add", event => {
  const { attachment, target } = event

  if (attachment.file) {
    const uploader = new TrixUploader(attachment, target)
    uploader.open()
  }
})
